import { getAceUser } from "./bin/fetchUser.js";
import { userEligibilityStatusCheck } from "./bin/userEligibilityCheck.js";
import { parseNvData } from "./bin/parseNvData.js";
import { handleInnerHTML } from "./bin/UI/appStatusRenderer.js";


async function initialConfiguration(baseURL){
    // get the query params
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    //get the ace user
    const aceUserId = params.get("aceUserId")
    const aceUserData = await getAceUser(aceUserId, baseURL)
    return aceUserData['enrollment_id']
}

async function appRunner(enrollmentId, applicationCardBody, eligibilityCheckButton, baseURL, loadingStatus="LOADING"){
    handleInnerHTML(applicationCardBody, loadingStatus, eligibilityCheckButton)

    const eligibilityCheckResult = await userEligibilityStatusCheck(enrollmentId, baseURL)
    //parse nv response!
    const dataParsed = parseNvData(eligibilityCheckResult)

    // redirect or change page logic!
    if (dataParsed.link){
        // TODO - remove me before deployment / testing with live endpoint!!!!!
        window.location.replace(dataParsed.link)
        // console.log("SENDING OVER!")
    }
    
    handleInnerHTML(applicationCardBody, dataParsed.status, eligibilityCheckButton)
    return dataParsed
}

//SECTION - main handler
async function main(){

    // TODO - get the url and figureout which endpoint to hit from that. 
    // parse out the baseURL from the page url
    const currentUrl = window.location.href;
    //TODO - Verify this works when we have the development account live!
    const baseURL = currentUrl.split(".")[0].split("//")[1]
    console.log(currentUrl);

    const eligibilityCheckButton = document.getElementById("checkStatusButton")
    const applicationCardBody = document.getElementById("application-card-body")
    //handle application configuration
    const userEnrollmentId = await initialConfiguration(baseURL)
    //run the initial runner function
    //see if they have an nv link, send off if they do
    let runnerResponse = await appRunner(userEnrollmentId, applicationCardBody, eligibilityCheckButton, baseURL)

    let pendingReviewIntervalId;

    if (runnerResponse.status == "PENDING_REVIEW"){
        pendingReviewIntervalId = createPendingReviewInterval()
        eligibilityCheckButton.addEventListener("click", eligibilityCheckButtonFunction)
    } else if (runnerResponse.status == "COMPLETE"){
        // * * * * * * * * * * * * * * * * * //
        //NOTE - Add a call to BotKit here!
        // * * * * * * * * * * * * * * * * * //
    }

    function createPendingReviewInterval(){
        let intervalId = setInterval(async () => {
            runnerResponse = await appRunner(userEnrollmentId, applicationCardBody, eligibilityCheckButton, baseURL)
        }, 10000) //300000
        return intervalId
    }

    async function eligibilityCheckButtonFunction(){
        // run the application status function again
        // reset the runner response
        runnerResponse = await appRunner(userEnrollmentId, applicationCardBody, eligibilityCheckButton, baseURL, "PENDING_REVIEW_LOADER")
        if(pendingReviewIntervalId){
            clearInterval(pendingReviewIntervalId)
        }
        pendingReviewIntervalId = createPendingReviewInterval()
    }
}

main()