function eligibiltiyStatusCheckUrls(stage){
    const map = {
        "dev-nvbuffer": {
            "URL": "https://kzk7s2tkv4.execute-api.us-east-1.amazonaws.com/prod/eligibility_status_check",
            "KEY": process.env.TELGOODEVDKEY
        },
        "test-nvbuffer": {
            "URL": "https://hjn692s7ej.execute-api.us-east-1.amazonaws.com/prod/eligibility_status_check",
            "KEY": process.env.TELGOOTESTDKEY
        },
        "nvbuffer": {
            "URL": "https://jaokxvw4pc.execute-api.us-east-1.amazonaws.com/prod/eligibility_status_check",
            "KEY": process.env.TELGOOPRODKEY
        }
    }
    return map[stage]
}

function userDatabaseUrls(stage){
    const map = {
        "dev-nvbuffer": {
            "URL": "https://2cpptmfwq5.execute-api.us-east-1.amazonaws.com/prod/user?aceUserId=",
            "KEY": process.env.DEVDBKEY
        },
        "test-nvbuffer": {
            "URL": "https://ib6t97xdxd.execute-api.us-east-1.amazonaws.com/prod/user?aceUserId=",
            "KEY": process.env.TESTDBKEY
        },
        "nvbuffer": {
            "URL": "https://dsh3hby7s8.execute-api.us-east-1.amazonaws.com/prod/user?aceUserId=",
            "KEY": process.env.PRODDBKEY
        }
    }
    return map[stage]
}

module.exports = {
    eligibiltiyStatusCheckUrls,
    userDatabaseUrls
}