export function parseNvData(nvData){

    const fromNvData = nvData["data"]
    console.log("FROM NV DATA:", fromNvData)
    const responseObj = {
        "link": "",
        "status": ""
    }
    if("_links" in fromNvData){
        const linkKey = Object.keys(fromNvData._links)[0]
        responseObj.link = fromNvData._links[linkKey].href
    }
    if("status" in fromNvData){
        responseObj.status = fromNvData.status
    }
    return responseObj
}