import { handlePendingReviewButton } from "./pendingReviewButtonHandler.js"

export function handleInnerHTML(domNode, status, buttonNode=false){

    //TODO - Check if these are the correct codes!
    const longLivedCodes = ["PENDING_REVIEW", "COMPLETE", "LOADING", "PENDING_REVIEW_LOADER"]
    console.log("STATUS HANDLER:", status)
    const statusMapHTML = {
        "PENDING_REVIEW": `
            <h5 class="card-title">Looks like your application is in review!</h5> 
            <p class="card-text">We will check the status of your application every 5 minutes.</p>
            <p class="card-text">If you would like to check sooner, refresh the page or click the button below</p>
        `,
        "COMPLETE": `
            <h5 class="card-title">Looks like your application is complete!</h5> 
            <p class="card-text">Naviagte back to your chat window to continue!</p>
        `,
        "LOADING": `
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            <h5 class="card-title">One moment please</h5>
        `,
        "PENDING_REVIEW_LOADER": `
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        `,
        "REDIRECTING": `
            <h5 class="card-title">Redirecting you to your application</h5>
        `
    }

    if(buttonNode != false){
        if (status == "LOADING" || status == "PENDING_REVIEW_LOADER" || status == "COMPLETE"){
            handlePendingReviewButton(buttonNode, true)
        }
    }
    
    if(status in longLivedCodes){
        domNode.innerHTML = statusMapHTML[status]
    } else {
        domNode.innerHTML = statusMapHTML["REDIRECTING"]
    }
}