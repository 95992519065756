import { userDatabaseUrls } from "./CONSTANTS";

export async function getAceUser(aceUserId, stage){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "redpocket");

    

    const {URL, KEY} = userDatabaseUrls(stage)
    const databaseURL = URL + aceUserId
    // const databaseURL = `https://2cpptmfwq5.execute-api.us-east-1.amazonaws.com/prod/user?aceUserId=${aceUserId}`
    myHeaders.append("x-api-key", KEY);
    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    const responseData = await fetch(databaseURL, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result["body"]
    })
    .catch(error => console.log('error', error));
    return responseData
}