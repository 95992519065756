import { eligibiltiyStatusCheckUrls } from "./CONSTANTS";

export async function userEligibilityStatusCheck(enrollmentId, stage){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "redpocket");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "enrollment_id": enrollmentId
    });


    //TODO - Switch the eligibiltiyStatusCheckUrl with the dynamic call
    const {URL, KEY} = eligibiltiyStatusCheckUrls(stage)
    myHeaders.append("x-api-key", KEY);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    // const eligibiltiyStatusCheckUrl = "https://kzk7s2tkv4.execute-api.us-east-1.amazonaws.com/prod/eligibility_status_check"
    const responseData = await fetch(URL, requestOptions)
        .then(response => response.json())
        .then(result => {
            return result
        })
        .catch(error => console.log('error', error));
    return responseData

    //NOTE - Sample object, also used for testing!
    // return {
    //     "data": {
    //         "_links": {
    //             "resolution": {
    //                 "href": "https://api-stg.universalservice.org/ebca-svc/security/getPage?id=620E0B38465DB11F2DD74A6445A9806D150181AEEB3ECFC5B1572F649B23D50F&token=79a5861d5ca0990b7214efc33998b927de5a7a4e079be49e188aaed93fa765ac"
    //             }
    //         },
    //         "activeSubscriber": "N",
    //         "applicationId": "B20215-22463",
    //         "eligibilityCheckId": "620E0B38465DB11F2DD74A6445A9806D150181AEEB3ECFC5B1572F649B23D50F",
    //         "eligibilityExpirationDate": "2023-04-27",
    //         "failures": {
    //             "STATE_FED_FAIL": "Subscriber's eligibility could not be confirmed. So you need upload Eligibility proof in National Verifier"
    //         },
    //         "status": "PENDING_REVIEW" //PENDING_REVIEW & COMPLETE
    //     },
    //     "msg": "Success",
    //     "msg_code": "RESTAPI000",
    //     "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7InVzZXJuYW1lIjoiRGVtby1SZWRwb2NrZXRVc2VyIiwicGFzc3dvcmQiOiJEZW1vLVJlZHBvY0sxMjNoeXV0cncyMSIsInBpbiI6IkRlbW8tMzQ1OTg3NDUzOTc0MzAiLCJ2ZW5kb3JfaWQiOiJEZW1vLVJlZHBvY2tldCIsImxvZ19pZCI6NzU0MDg5LCJ1cmwiOiJodHRwczovL2RlbW8tcmVkcG9ja2V0LWNhLnRlbGdvbzUuY29tL3ZjYXJlT3NzQXBpLyJ9LCJleHAiOjE2Nzg3Mzc1NjJ9.E3HoFl_F7TshB0znMBaT47XfNxGeiE8f9qpzrgaxZyk"
    // }
}